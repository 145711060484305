<template>
  <header class="center header" id="header">
    <router-link class="logo" :to="{ name: 'home' }">
      <img src="@/assets/img/logo.png" alt="EarnForex" />
    </router-link>

    <nav v-if="mode">
      <ul class="nav">
        <li>
          <router-link
            :to="{ name: 'brokers' }"
            class="nav_link"
            :class="this.$route.name === 'brokers' ? 'active' : ''"
          >
            Brokers
          </router-link>
        </li>
        <li v-if="mode !== 'client'">
          <router-link
            :to="{ name: 'brokerCreate' }"
            class="nav_link"
            :class="this.$route.name === 'brokerCreate' ? 'active' : ''"
          >
            Add Broker
          </router-link>
        </li>
        <li v-if="mode !== 'client'">
          <router-link
            :to="{ name: 'settings' }"
            class="nav_link"
            :class="this.$route.name === 'settings' ? 'active' : ''"
          >
            Update screenshots
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="settings_link" v-if="name">
      <span class="settings_link--admin">
        {{ name.slice(0, 1).toUpperCase() }}
      </span>
      {{ name }}
    </div>
    <button v-if="mode && isAdmin" class="logout_btn" @click.prevent="logOut">
      Log out
    </button>

    <notificationTop
      :text="getNotification ? getNotification.text : null"
      :type="getNotification ? getNotification.type : null"
    />
  </header>
</template>

<script>
import notificationTop from "@/components/notification";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      name: "",
      mode: "",
    };
  },
  components: {
    notificationTop,
  },
  computed: {
    ...mapGetters(["getNotification", "getAdminEmail"]),
    isAdmin() {
      return localStorage.getItem("Mode") === "manager";
    },
  },
  methods: {
    async logOut() {
      if (localStorage.getItem("Mode") === "manager") {
        await this.clearLocalStorage();
        this.$router.push({ name: "adminLogin" });
      } else {
        await this.clearLocalStorage();
        this.$router.push({ name: "home" });
      }
    },

    async clearLocalStorage() {
      localStorage.removeItem("Mode");
      localStorage.removeItem("login");
      localStorage.removeItem("authToken");
    },
  },
  created() {
    this.name = localStorage.getItem("login");
    this.mode = localStorage.getItem("Mode");
  },
};
</script>
