import gql from "graphql-tag";

export let paymentOptions = gql`
  {
    paymentOptions {
      name
      id
    }
  }
`;
export let countryOffices = gql`
  {
    countryOffices {
      name
      id
    }
  }
`;
export let accountCurrencies = gql`
  {
    accountCurrencies {
      name
      id
      iso
    }
  }
`;
export let tradingPlatforms = gql`
  {
    tradingPlatforms {
      name
      id
    }
  }
`;
export let websiteLanguages = gql`
  {
    websiteLanguages {
      name
      id
    }
  }
`;
export let supportLanguages = gql`
  {
    supportLanguages {
      name
      id
    }
  }
`;
export let supportMethods = gql`
  {
    supportMethods {
      name
      id
    }
  }
`;

export let brokerList = gql`
  {
    brokerList {
      id
      anchor
      name
      company
      contactsForAdmin
      reviewed
      disabled
      changedAt
      noImportantFields
      locked
      relatedBrokers {
        id
        rootStatus
        reviewed
        name
        anchor
        locked
      }
    }
  }
`;

export let baseBrokerInfoList = gql`
  {
    baseBrokerInfoList {
      id
      anchor
      name
    }
  }
`;
export let languages = gql`
  {
    languages {
      name
      slug
    }
  }
`;
export let options = gql`
  {
    options {
      id
      name
    }
  }
`;
export let vipConditionOptions = gql`
  {
    vipConditionOptions {
      id
      name
    }
  }
`;

export let licenses = gql`
  {
    licenses {
      id
      name
    }
  }
`;
export let instruments = gql`
  {
    instruments {
      id
      name
    }
  }
`;
export let isBrokerAnchorBusy = gql`
  query isBrokerAnchorBusy($anchor: String!, $brokerId: ID) {
    isBrokerAnchorBusy(anchor: $anchor, brokerId: $brokerId)
  }
`;

export let isAccountTypeBusy = gql`
  query isAccountTypeBusy($brokerId: ID!, $name: String!) {
    isAccountTypeBusy(brokerId: $brokerId, name: $name)
  }
`;

export let getUserBrokers = gql`
  {
    getUserBrokers {
      id
      anchor
      name
      company
      contactsForAdmin
      reviewed
      disabled
      changedAt
      noImportantFields
      locked
      relatedBrokers {
        id
        rootStatus
        reviewed
        name
        anchor
        locked
      }
    }
  }
`;

export let brokerLogs = gql`
  {
    brokerLogs {
      id
      brokerId
      accountTypeId
      typeLog
      changes
      logData
    }
  }
`;
// all brokers screenshots creating
export let screenshotsUpdateStatus = gql`
  {
    screenshotsUpdateStatus {
      brokers
      finished
      success
    }
  }
`;
export let allScreenshotLogs = gql`
  {
    allScreenshotLogs {
      id
      downloadLink
      created_at
    }
  }
`;
export let allArchiveScreenshots = gql`
  {
    allArchiveScreenshots {
      url
      dateExpired
    }
  }
`;

export let currencyPairs = gql`
  {
    currencyPairs {
      id
      pair
    }
  }
`;

export let operatingSystems = gql`
  {
    operatingSystems {
      id
      name
    }
  }
`;
